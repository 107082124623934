import { defineStore } from "pinia";
import { userService } from '../../helpers/authservice/user.service';
import router from '../../router/index'
import { useNotificationStore } from "./notification"

const user = JSON.parse(localStorage.getItem('user'));

export const useAuthFakeStore = defineStore("authFake", {
    state: () => ({
        status: user ? { loggeduser: true, loading: false } : {},
        user: user || null
    }),
    actions: {
        login(email, password, token, role, type) {
            const notificationStore = useNotificationStore();
            this.loginRequest(email)
            userService.login(email, password, token, role, type)
                .then(
                    (user) => {
                        this.loginSuccess(user)
                        if (user.role == 1) {
                            router.push('/dashboard');
                        } else {
                            router.push('/');
                        }
                        notificationStore.success('Login successful');
                    },
                    (error) => {
                        this.loginFailure()
                        notificationStore.error(error);
                    }
                );
            notificationStore.email_status = localStorage.getItem('status');
        },
        logout() {
            // userService.logout();
            this.status = {};
            this.user = null;
            localStorage.removeItem('user');
            localStorage.removeItem('access_token');
            localStorage.removeItem('device_id');
            localStorage.clear();
            router.push('/login');
        },
        registeruser(user) {
            const notificationStore = useNotificationStore();
            this.registerRequest(user)
            userService.register(user)
                .then(
                    (user) => {
                        this.registerSuccess(user)

                        localStorage.setItem("userEmail", user.data.email);
                        localStorage.setItem("code", user.data.verification_code);
                        notificationStore.success('Registration successful');
                        // router.push('/login');
                        router.push('/email-verification');
                    },
                    (error) => {
                       this.registerFailure()
                        notificationStore.error(error);
                    }
                );
        },
        loginRequest(email) {
            this.status = { loggingIn: true, loading: true }
            this.user = email
        },
        loginSuccess(user) {
            this.status = { loggeduser: true, loading: false };
            this.user = user;
        },
        loginFailure() {
            this.status = {};
            this.user = null;
        },

        registerRequest() {
            this.status = { registering: true };
        },
        registerSuccess() {
            this.status = {};
        },
        registerFailure() {
            this.status = {};
        },
        verifyemail(verification_key, email, isVerify) {

            let isVerified = isVerify;
            this.verifyRequest(email)
            const notificationStore = useNotificationStore();
            userService.verifyEmail(verification_key, email, isVerify)
                .then(
                    data => {
                        this.verifySuccess(data)
                        notificationStore.success('Verification successful');

                        if (isVerified == true) { //isVerify is true 
                            router.push('/login');
                        } else {
                            router.push('/reset-password');
                        }
                        notificationStore.clear();
                    },
                    error => {
                        // this.verifyFailure()
                        notificationStore.error(error);
                    }
                );
        },
        verifyRequest() {
            this.status = { verifying: true };
        },
        verifySuccess() {
            this.status = {};
        },
        forgotpassword(data) {
            const notificationStore = useNotificationStore();
            this.forgotPasswordRequest();
            userService.forgotPassword(data)
                .then(
                    data => {
                        this.forgotPasswordSuccess(data);
                        notificationStore.success('Password changed successful');
                        router.push('/login');
                    },
                    error => {
                        this.forgotPasswordFailure(error);
                        notificationStore.error(error)
                    }
                );
        },
        forgotPasswordRequest() {
            this.status = { isforgot: true };
        },
        forgotPasswordSuccess() {
            this.status = {};
        },
        forgotPasswordFailure() {
            this.status = {};
        },
    },
})