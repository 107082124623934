import { api_url } from '../../constants/constant';

export const userService = {
    login,
    logout,
    register,
    verifyEmail,
    forgotPassword
};

function login(email, password, token, role, type) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, token, role, type })
    };
    if(type == "adminascustomer"){
        localStorage.setItem("isAdmin", "1");
    }else{
        localStorage.setItem("isAdmin", "0");
    }
    let url = (token != null) ? `google-login` : `login`;

    return fetch(api_url + url, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.data.accessToken) {
                // store user detaiaccessTokenls and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user.data.userDetails));
                localStorage.setItem('userimg', JSON.stringify(user.data.userimg));
                localStorage.setItem('access_token', JSON.stringify(user.data.accessToken));
                localStorage.setItem('cookie', JSON.stringify(user.data.userDetails.device_id));

                //default otp
                localStorage.setItem('otp', JSON.stringify(user.data.otp));
            }
            return user.data.userDetails;
        });
}

function logout() {
    // Remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('device_id');
    localStorage.clear();
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: null
    };

    return fetch(api_url + `logout`, requestOptions)
        .then(response => {
            // Check if the response status is OK (200)
            if (!response.ok) {
                throw new Error(`Logout failed with status: ${response.status}`);
            }

            // Return an empty object if the response does not have a body
            if (response.status === 204) {
                return {};
            }

            // Parse the response as JSON
            return response.json();
        })
        .then(handleResponse)
        .catch(error => {
            // Handle and log the error
            console.error('Logout error:', error);
        });
}


function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    // return fetch(`register`, requestOptions).then(handleResponse);
    return fetch(api_url + `register`, requestOptions).then(handleResponse);
}


function verifyEmail(verification_key, email, isVerify) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ verification_key, email, isVerify })
    };
    return fetch( api_url + `mail-verification`, requestOptions).then(handleResponse);
}

function forgotPassword(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch( api_url + `forgot-password`, requestOptions).then(handleResponse);
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            // if (response.status === 401) {
            //     // auto logout if 401 response returned from api
            //     logout();
            //     location.reload(true);
            // }
            if(data.data){
                localStorage.setItem('status', data.data.status)
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}


