import { createApp } from 'vue'

import App from './App.vue'
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import { registerScrollSpy } from 'vue3-scroll-spy';

import { vMaska } from "maska"
import i18n from "./i18n"

import BootstrapVueNext from 'bootstrap-vue-next'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import "@/assets/scss/app.scss";
import "@vueform/multiselect/themes/default.css"

// PINIA
import pinia from '@/state/pinia'

import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';

import vue3GoogleLogin from 'vue3-google-login'


import Echo from 'laravel-echo';

import Pusher from 'pusher-js';

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_WEBSOCKET_KEY,
    cluster: 'mt1',
    wsHost: process.env.VUE_APP_WEBSOCKET_SERVER,
    // wsPort: process.env.VUE_APP_PUSHER_PORT,
    // wssPort: process.env.VUE_APP_PUSHER_PORT,
    forceTLS: false,
    enabledTransports: ['ws', 'wss'],
    disabledStats: true,
    authEndpoint: 'https://app-test-01.intelipanel.com/broadcasting/auth',
    auth: {
      headers: {
        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem("access_token")),
      }
    },
});



createApp(App)
  .use(pinia)
  .use(router)
  .use(require('vue-chartist'))
  .use(BootstrapVueNext)
  .use(VueApexCharts)
  .use(vClickOutside)
  .use(i18n)
  .use(registerScrollSpy)
  .use(vue3GoogleLogin, {
    clientId: '608578407854-kfib2i7q7aetg1jfr23uejfso5091tk9.apps.googleusercontent.com'
  })
  .directive("maska", vMaska)
  .mount('#app')

  // 452190390124-9p8d3o0b6qn48j35cdorlm0vokf0fi4p.apps.googleusercontent.com // intelipanel dev
  // 740054991886-r00ijdkru3klff5pr58viahqmtd4j0lg.apps.googleusercontent.com
  // 381559313865-ihfktasq5ih2b48dic3d6d59nmn1sr0b.apps.googleusercontent.com // current one
  // 608578407854-kfib2i7q7aetg1jfr23uejfso5091tk9.apps.googleusercontent.com - test