import { defineStore } from "pinia";

export const useNotificationStore = defineStore("notification", {
    state: () => ({
        type: null,
        message: null,
        status: 0, 
        email_status: 0,
        variant: null
    }),
    actions: {
        success(message) {
            this.type = 'alert-success';
            this.message = message;
            this.status = 0;
            this.variant = 'success';
        },
        error(message) {
            this.type = 'alert-danger';
            this.message = message;
            this.status = 1;
            this.variant = 'danger';
        },
        clear() {
            this.type = null;
            this.message = null;
            this.status = 0;
        }
    }
});
